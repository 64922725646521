import React, {setGlobal} from 'reactn';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import App from './App';

// Initialize the global store
setGlobal({
  user: {
    isAuthenticated: false,
    token: '',
    name: '',
    avatar: '',
    locations: []
  },
  interactionData: {
    nurseId: null,
    location: null,
    month: '',
    day: '',
    year: '',
    visitType: '',
    specialties: [],
    customSpecialties: [],
    diets: [],
    customDiets: [],
    interventions: [],
    customInterventions: [],
    changes: [],
    customChanges: [],
    reasons: [],
    customReasons: [],
    email: '',
    cookingClassOptIn: null,
  },
  resultPath: '',
  breadcrumbs: [],
  viewHasHeader: false,
  viewHasBreadcrumbs: false,
  showActivityIndicator: false
});

ReactDOM.render(
  <BrowserRouter>
    <App/>
  </BrowserRouter>
  , document.getElementById('root'));

if ('serviceWorker' in navigator) {

  window.addEventListener('load', function () {

    // Register the service worker
    navigator.serviceWorker.register('/app/themes/upmc-na/service-worker.js').then(reg => {

      reg.addEventListener('updatefound', () => {

        // An updated service worker has appeared in reg.installing!
        const newWorker = reg.installing;

        newWorker.addEventListener('statechange', () => {

          // Has service worker state changed?
          switch (newWorker.state) {
            case 'installed':

              // There is a new service worker available, show the notification
              if (navigator.serviceWorker.controller) {
                // eslint-disable-next-line no-restricted-globals
                if (confirm(`A content update is available!. Tap OK to update`)) {
                  window.location.reload();
                }
              }

              break;
            default:
          }
        });
      });
    });
  });
}

// Prevent background scroll in iOS
const preventDefault = e => e.preventDefault();
// When rendering our container
window.addEventListener('touchmove', preventDefault, {
  passive: false
});
// Remember to clean up when removing it
window.removeEventListener('touchmove', preventDefault);
